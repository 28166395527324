import React from 'react';
import aboutTopImg from "../../img/about_itworks.png";
import bldImg from "../../img/office_bld.jpg";
import Iframe from 'react-iframe';
import Info from '../top/info';
import Flow from '../top/flow';
import Contact from '../top/contact';
import './about.scss';


const googleMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.0102854358006!2d135.5058075152317!3d34.67968988043997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e722e8e76f6b%3A0xa4e85ae7b20bd28b!2z44CSNTQxLTAwNTcg5aSn6Ziq5bqc5aSn6Ziq5biC5Lit5aSu5Yy65YyX5LmF5a6d5a-655S677yR5LiB55uu77yX4oiS77yZIOWguueti-acrOeUuuODl-ODqeOCtuODk-ODqw!5e0!3m2!1sja!2sjp!4v1599721084588!5m2!1sja!2sjp";


function About({movePage}) {
  return (
    <div className="about">
      <div className="about_title">ITワークスについて</div>
      <div className="message_group">
        <div className="left">
          <div className="title">就労継続支援B型<span className="pc">　</span><br className="sp"/>ITワークス大阪</div><br/>
          <div className="message">
            <div>時代に合わせてパソコン作業に特化した事業所を設立しました。</div><br/>

            <div>在宅就労（テレワーク）にも対応している就労継続支援Ｂ型です。</div><br />

            <div>
            Web業界に携わってきた経験豊富なメンバーが運営しているため、<br />
            実践的な作業をすることができます。
            </div><br/>

            <div>就労移行のように学ぶこともできます。</div><br />

            <div>学んだ事を就労Ｂ型の生産活動で生かすこともできます。</div>
          </div>
        </div>
        <div className="right">
          <img src={aboutTopImg} alt=""/>
        </div>
      </div>
      <div className="appearance">
        <div className="title">事業所情報</div>
        <div className="appearance_info">
          <div className="left">
            <div className="bld_img"><img src={bldImg} alt="Itworks-osakaの建物"/></div>
          </div>
          <div className="right">
            <div className="message">
              就労継続支援B型事務所<span className="pc">　</span><br className="sp"/>ITワークス大阪<br /><br />
              〒541-0057<br />
              大阪市中央区北久宝寺町1-7-9<br />
              堺筋本町プラザビル 201号室<br /><br />
              TEL:
              <a href="tel:0669488349">06-6948-8349<br/></a>
              E-mail:itwoo@itworks.fun<br />
              営業時間:9:00~18:00<br /><br />
            </div>
            <br />
            <div className="map">
              <Iframe src={googleMap} />
            </div>
          </div>
        </div>
      </div>
      <Info />
      <Flow />
      <Contact movePage={movePage}/>
    </div>
  );
}

export default About;
